import { createRouter, createMemoryHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        redirect: '/home'
    }, {
        path: '/home',
        name: 'home',
        component: () => import('../views/homePage.vue')
    }, {
        path: '/service',
        name: 'loanService',
        component: () => import('../views/loanService.vue')
    }, {
        path: '/about',
        name: 'aboutUS',
        component: () => import('../views/aboutUS.vue')
    }, {
        path: '/contact',
        name: 'contactUS',
        component: () => import('../views/contactUS.vue')
    }, {
        path: '/privatePolicy_BahtSatchel',
        name: 'privatePolicy_BahtSatchel',
        component: () => import('../views/privatePolicyBahtSatchel.vue')
    }, {
        path: '/loanAgreement_BahtSatchel',
        name: 'loanAgreement_BahtSatchel',
        component: () => import('../views/loanAgreementBahtSatchel.vue')
    }, {
        path: '/privatePolicy_CreditWave',
        name: 'privatePolicy_CreditWave',
        component: () => import('../views/privatePolicyCreditWave.vue')
    }, {
        path: '/loanAgreement_CreditWave',
        name: 'loanAgreement_CreditWave',
        component: () => import('../views/loanAgreementCreditWave.vue')
    }
]

const router = createRouter({
    history: createMemoryHistory(),
    routes
})

export default router;
